import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { catchError, switchMap, take, tap } from 'rxjs';
import { AuthService, IconService } from '@ess-front/shared';
import { ButtonComponent } from '@shared/button/button.component';
import { SafeHtml } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { UserDomainService } from '@app/domain/user-domain.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MagicLinkService } from '@app/api/magic-link.service';
import { environment } from '@environment';
import { NgClass } from '@angular/common';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
  selector: 'ess-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, FormsModule, ButtonComponent, RouterModule, NgClass, TranslocoDirective],
  animations: [
    trigger('slideInOutLeft', [
      state('visible', style({ visibility: 'visible', transform: 'translateX(0%)' })),
      state('hidden', style({ visibility: 'hidden', transform: 'translateX(-100%)' })),
      transition('hidden => visible', [animate('300ms ease-in')]),
      transition('visible => hidden', [animate('300ms ease-in')]),
    ]),
    trigger('slideInOutRight', [
      state('visible', style({ visibility: 'visible', transform: 'translateX(-100%)' })),
      state('hidden', style({ visibility: 'hidden', transform: 'translateX(0%)' })),
      transition('hidden => visible', [animate('300ms ease-in')]),
      transition('visible => hidden', [animate('300ms ease-in')]),
    ]),
  ],
})
export class LoginFormComponent {
  email = '';
  formSubmitted = false;
  magicLinkLogin: FormGroup;
  passwordIcon: SafeHtml | null;
  showForm = false;
  showPassword = false;
  @Input() isModal = false;
  @Output() loginDone = new EventEmitter();
  @Output() modalClose = new EventEmitter();

  loginForm = new FormGroup({
    email: new FormControl(''),
    password: new FormControl(''),
    keepLogged: new FormControl(false),
  });

  constructor(
    private authService: AuthService,
    private iconService: IconService,
    private magicLinkService: MagicLinkService,
    private userDomainService: UserDomainService,
    private fb: FormBuilder,
  ) {
    this.passwordIcon = this.iconService.getSVG('eye');
    this.magicLinkLogin = this.fb.group({
      email: new FormControl('', [Validators.required, Validators.email]),
    });
  }

  onSubmit() {
    const { email, password, keepLogged } = this.loginForm.value;
    if (email && password) {
      this.authService
        .login(email, password, keepLogged ?? false, this.isModal ? null : '/members-area')
        .pipe(
          tap(() => this.loginDone.emit()),
          switchMap(() => this.userDomainService.getUser$()),
          take(1),
          catchError(async () => this.loginForm.setErrors({ invalid: true })),
        )
        .subscribe();
    } else {
      this.loginForm.setErrors({ invalid: true });
    }
  }

  sendMagicLink(login: FormGroup) {
    this.formSubmitted = true;
    this.email = login.value.email;
    const redirectUrl = this.isModal ? window.location.href : `${environment.travelDomain}/members-area`;
    localStorage.setItem('ess_magic_url', redirectUrl);
    this.magicLinkService.sentEmail$(this.email, redirectUrl).pipe(take(1)).subscribe();
  }
}
